<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="600">
            <v-card>
              <v-toolbar dark>
                <v-card-title class="headline">
                  {{ `${edit ? 'Update' : 'Add'}` }}  route category
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="cancelForm">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                  v-model="route.title"  outlined dense label= "Title ">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <label for="">Description</label>
                            <ckeditor :config="editorConfig" v-model="route.description"></ckeditor>
                        </v-col>
                        <v-col cols="6">
                            <v-switch
                                    v-model="route.is_active" outlined dense label="Active">
                            </v-switch>
                        </v-col>
                        <v-col cols="12">
                            <div class="float-right">
                                <v-btn depressed @click="cancelForm"  class="text-gray btn btn-standard cancel-btn">Cancel</v-btn>
                                <v-btn v-if="checkIsAccessible('route', 'create')" depressed :loading="isBusy" @click="createOrUpdate"  class="ml-2 text-white btn btn-primary">Save</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>

            </v-card>

        </v-dialog>

    </v-app>
</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import RoutesCategoryService from "@/core/services/Transport/RoutesCategory/RoutesCategoryService";
    const routesCategoryService = new RoutesCategoryService();
    export default {
        validations: {
            route: {
                title: {required}
            }
        },
        data() {
            return {
                edit: false,
                dialog: false,
                sizeExceed: false,
                isBusy: false,
                route: {
                    title: null,
                    route_code: null,
                    start_point: null,
                    end_point: null,
                    is_active: true
                },
                editorConfig: {
                  versionCheck: false,
                    toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
                },
            };
        },
        methods: {
            showModal(id = null) {
                if (id) {
                    this.edit = true
                    this.__get(id)
                }else{
                    this.resetForm()
                }
               this.dialog = true;
            },
            cancelForm(){
                this.resetForm();
                this.closeDialog();
            },
            closeDialog(){
                this.dialog = false;
            },
            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            }, __get(id) {
                routesCategoryService.show(id).then(response => {
                    this.route = response.data.route
                })
            },
            // generateFd() {
            //     // console.log(this.video)
            //     let fd = new FormData();
            //     for (let key in this.route) {
            //         if (key == "is_active" && this.video["is_active"]) {
            //             fd.append("is_active", this.video ? 1 : 0);
            //         } else {
            //             if (this.video[key] != null) {
            //                 fd.append(key, this.video[key]);
            //             }
            //         }
            //     }
            //     return fd;
            // },
            createOrUpdate() {
                this.$v.route.$touch();
                if (this.$v.route.$error) {
                    setTimeout(() => {
                        this.$v.route.$reset();
                    }, 3000);
                } else {
                    let fd = this.route;
                    if (this.edit) {
                        this.__update(fd);
                    } else {
                        this.__create(fd);
                    }
                }
            },
            __update(fd) {
                this.isBusy = true;
                routesCategoryService
                    .update(this.route.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information updated");
                        this.$emit("refresh");
                        this.resetForm();
                        this.closeDialog();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },


            __create(fd) {
                this.isBusy = true;
                routesCategoryService
                    .store(fd)
                    .then(response => {
                        this.isBusy = false;
                        this.resetForm();
                        this.closeDialog();
                        this.$emit("refresh");
                        this.$snotify.success("Information added");

                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        console.log(error);
                    });
            },
            resetForm() {
                this.$v.route.$reset();
                this.edit = false;
                this.route = {
                    title: null,
                    route_code: null,
                    start_point: null,
                    end_point: null,
                    is_active: true
                };
            }
        }
    };
</script>
